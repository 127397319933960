(function(){
  const burger = document.getElementById('jsBurger');
  // ない場合はなにもしない
  if( ! burger ) return;

  // グローバルナビゲーションの要素
  const globalNav = document.getElementById('constructGlobalnav');

  const ignoreElements = [
    document.getElementById('constructGlobalnav'),
    document.getElementById('constructGlobalnav'),
  ];

  document.onclick = (e) => {
    // クリックされた要素
    const clickElement = e.target;

    //console.log(clickElement)

    // burger が押されているかを確認します
    const pressed = clickElement.getAttribute('aria-pressed') === 'true';

    // クリックされた要素が burger か？
    if( clickElement === burger ){
      // burger の aria-pressed を反対の状態に変更します
      burger.setAttribute('aria-pressed', !pressed);
      //
      globalNav.setAttribute('aria-hidden', pressed);

      document.body.style.overflow = 'hidden';
      return;
    };
  };
}());
